h1 {
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
}

.canvas {
  padding: 20px 40px;
}

.picker {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.h1 {
  margin-top: 40px;
  margin-bottom: 0px;
  font-size: 1.5rem;
  text-align: center;
}

.canvas1 {
  margin-top: 100px;
  display: flex;
  flex-direction: row-reverse;
}

.comment {
  margin-top: 10px;
  text-align: right;
  font-weight: bold;
}

.svg {
  position: relative;
  width: 50%;
}

.svg .caption {
  text-align: center;
  color: #555;
}

.svg .mouse {
  background-color: #f44336;
  border-radius: 50%;
  border: 3px solid #000;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 59px;
  left: 62px;
}

.button-wrapper {
  margin: 100px 16px;
  text-align: center;
}

.button {
  display: inline-block;
  background-color: #6366f1;
  color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  padding: 16px 12px;
  font-weight: bold;
  text-decoration: none;
}

.fadein {
  animation-name: fadein;
  animation-duration: 2s;
}

.delay {
  animation-name: delay;
  animation-duration: 3s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delay {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
